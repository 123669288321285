import React from "react"
import { FaEnvelope } from "react-icons/fa"
import { ProfileType } from "../../types"

const Header = ({ initials }) => (
  <header className="flex justify-between p-4 lg:px-8">
    <span className="inline-flex items-center justify-center text-xl font-bold text-center border-2 border-solid rounded-full w-14 h-14 lg:mt-4 font-header text-front border-front">
      {initials}
    </span>
    <div className="flex flex-col items-end text-center lg:items-center">
      <a
        className="flex items-center justify-center px-2 font-semibold leading-tight transition-opacity duration-150 rounded-full w-14 h-14 font-header bg-lead text-lead-text lg:w-auto lg:h-auto lg:px-6 lg:py-2 lg:rounded-lg lg:self-start lg:mt-4 hover:opacity-75"
        href="mailto:me@oguzeray.dev?subject=Contacted from your website"
      >
        <FaEnvelope className="inline-block w-6 h-6 lg:hidden" />
        <span className="hidden lg:block">Contact me</span>
      </a>
      <p className="mt-2 font-serif text-sm leading-normal whitespace-pre-line opacity-60 font-text">
         me@oguzeray.dev
      </p>
    </div>
  </header>
)

Header.propTypes = {
  initials: ProfileType.initials,
}

export default Header
