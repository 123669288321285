import React from "react"
import { arrayOf, shape, WorkHistoryType } from "../../types"
import "./work-history.css"

const WorkHistory = ({ history }) => {
  return (
    <>
      <h5 className="mt-12 mb-3 font-bold text-center uppercase text-md font-header text-front">
        Work history
      </h5>
      <div className="relative flex flex-col pt-6 pb-6 history">
        {history.map(({ company, period, position, url }, i) => (
          <div
            className="relative w-1/2 py-4 history-entry"
            key={`${company}_${i}`}
          >
            <span className="absolute inline-flex items-center justify-center rounded-full dot-bg w-14 h-14 bg-line">
              <span className="w-5 h-5 rounded-full dot bg-back-light" />
            </span>
            {url ? (
              <h4 className="subpixel-antialiased">
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-semibold transition-opacity duration-150 hover:opacity-75"
                >
                  {company}
                </a>
              </h4>
            ) : (
              <h4 className="subpixel-antialiased">{company}</h4>
            )}

            {position && (
              <h5 className="mb-1 text-sm font-normal">{position}</h5>
            )}
            {period && (
              <span className="text-sm font-medium opacity-50">{period}</span>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

WorkHistory.propTypes = {
  history: arrayOf(shape(WorkHistoryType)),
}

export default WorkHistory
