import { GatsbyImage } from "gatsby-plugin-image"
import imageGif from "../../../assets/checkbox.gif"
import React from "react"
import { ProjectType } from "../../types"
import ProjectIcon from "./project-icon"
import ProjectStatus from "./project-status"
import ProjectTags from "./project-tags"

const Project = props => {
  const { name, image, url, description, status, tags, icon } = props

  
  return (
    <div className="relative flex flex-wrap p-4 mb-6 text-sm bg-no-repeat border-t-4 border-line bg-back-light lg:p-8">
      {image && (
        <div className="w-full pb-4 lg:w-2/5 lg:pr-8 lg:pb-0">
        {name.includes("Checkbox") ?
          <img src={imageGif} alt="Checkbox habibi shashan" /> 
          :
          <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={name} /> }
        </div>
      )}
      <div className="lg:flex-1">
        <h4 className="font-bold">{name}</h4>
        {url && (
          <a
            className="underline break-all transition-opacity duration-150 text-front hover:opacity-75"
            href={url}
            rel="noreferrer noopener"
            target="_blank"
          >
            {url}
          </a>
        )}
        <p className="w-full py-4 whitespace-pre-line">{description}</p>
        <ul className="pr-2">
          {status && <ProjectStatus status={status} />}
          {tags && <ProjectTags tags={tags} />}
        </ul>

        {icon && <ProjectIcon icon={icon} />}
      </div>
    </div>
  )
}

Project.propTypes = ProjectType

export default Project
