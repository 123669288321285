import React from "react"
import { ProfileType } from "../../types"

const About = ({ about }) => (
  <>
    <h5 className="mb-3 text-lg font-bold uppercase font-header text-front">
      About
    </h5>
    <div className="pb-12 text-base leading-normal whitespace-pre-line font-text">
      {about}
    </div>
  </>
)

About.propTypes = {
  about: ProfileType.about,
}

export default About
